import React, { useEffect, useState } from "react";
import Button from "../Button";
import Modal from "../Modal";
import Input from "../Input";
import InputSelect from "../InputSelect";
import api from "../../services/api";
import { toast } from "react-toastify";
import { renderError } from "../../helpers/errors";
import { hasPermission } from "../../services/auth";
import origins from "../../seeds/origins";

const types = [
    {label: 'Cliente', value: 'customer'}
];


if(hasPermission('company_supplier')){
    types.push({label: 'Fornecedor de gravação', value: 'customization'});
    types.push({label: 'Fornecedor de produto', value: 'product'});
}
if(hasPermission('company_billing')){
    types.push({label: 'Faturamento', value: 'billing'});
}


export const ModalCreateCompany = ({show, close, type, showSuccess, companyStored}) => {
    const [loading, setLoading] = useState(false);

    const [companyTradeName, setCompanyTradeName] = useState('');
    const [companyCompanyName, setCompanyCompanyName] = useState('');
    const [companyCnpj, setCompanyCnpj] = useState('');
    const [companyPhone, setCompanyPhone] = useState('');
    const [companyType, setCompanyType] = useState([]);
    const [origin, setOrigin] = useState('');

    useEffect(() => {
        if(!show) clean();
    }, [show]);

    const storeCompany = () => {
        setLoading(true);

        api.post(`/company`, {
            phone: companyPhone,
            trade_name: companyTradeName,
            company_name: companyCompanyName,
            cnpj: companyCnpj,
            type: type ?? companyType,
            origin
        }).then(res => {
            {showSuccess !== false &&
                toast.success('Empresa cadastrada com sucesso');
            }
            if (typeof companyStored === 'function') {
                companyStored(res.data.resource);
            }
            setLoading(false);
            clean();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setCompanyTradeName('');
        setCompanyCompanyName('');
        setCompanyCnpj('');
        setCompanyPhone('');
        setCompanyType([]);
        setOrigin('');
    }

    return (
        <Modal
                title={`Cadastrar empresa`}
                show={show}
                close={close}
                footer={
                    <Button 
                        type={`primary`}
                        size={`small`}
                        text={`Cadastrar`}
                        svg={`save-white`}
                        action={storeCompany}
                        loading={loading}
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`CNPJ`} change={setCompanyCnpj} value={companyCnpj} mask={`convertToCnpj`} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Input type={`text`} label={`Razão social`} change={setCompanyCompanyName} value={companyCompanyName} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Input type={`text`} label={`Nome fantasia`} change={setCompanyTradeName} value={companyTradeName} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Input type={`text`} label={`Telefone`} change={setCompanyPhone} value={companyPhone} mask={`convertToPhone`} />
                        </div>
                    </div>
                    {!type &&
                        <div className="row">
                            <div className="col">
                                <InputSelect label={`Tipo`} isMulti={true} options={types} change={setCompanyType} value={companyType} />
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col">
                            <InputSelect label={`Onde nos conheceu`} options={origins} change={setOrigin} value={origin} />
                        </div>
                    </div>      
                                 
                </div>
            </Modal>
    );
}

export default Modal;