import React, { useState, useEffect } from "react";

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import { renderError } from "../../helpers/errors";
import api from "../../services/api";
import moment from "moment";
import { convertIntToMoney } from "../../helpers/conversions";
import DotStatus from "../../components/DotStatus";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { ModalFilter } from "./modals/ModalFilter";
import { Link } from "react-router-dom";
import { hasPermission } from "../../services/auth";
import { ModalDelete } from "../../components/ModalDelete";
import Pagination from "../../components/Pagination";
import { countOrderPendingConferencesTag } from "./helpers/conference";
import { ModalExport } from "../../components/ModalExport";

export const OrderList = () => {
    const [loading, setLoading] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [filters, setFilters] = useState('?');
    const [customFilters, setCustomFilters] = useState('');

    const [orders, setOrders] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [tab, setTab] = useState('Todos');
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const [orderToDelete, setOrderToDelete] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [totalResults, setTotalResults] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPrevPage, setHasPrevPage] = useState(false);

    const [selectedIds, setSelectedIds] = useState([]);
    const [showConfirmMultipleDelete, setShowConfirmMultipleDelete] = useState(false);

    useEffect(() => {
        getStatuses();
    }, []);

    useEffect(() => {
        getOrders();
    }, [currentPage,filters, customFilters, selectedStatus]);

    useEffect(() => {
        generateData();
    }, [orders, tab]);

    const getStatuses = () => {
        api.get(`/status?filters[0][type][equal]=order`).then(res => {
            setStatuses(res.data.resources);
        });
    }

    const getOrders = () => {
        setLoading(true);
        let statusFilter = selectedStatus === null ? '' : `&filters[0][related][status.id][equal]=${selectedStatus}`;
        api.get(`/order/paginate?${filters}${customFilters}${statusFilter}&page=${currentPage}`).then(res => {
            setOrders(res.data.resources.data);
            setCurrentPage(res.data.resources.current_page);
            setLastPage(res.data.resources.last_page)
            setPerPage(res.data.resources.per_page)
            setTotalResults(res.data.resources.total)
            setHasNextPage(res.data.resources.next_page_url !== null);
            setHasPrevPage(res.data.resources.prev_page_url !== null);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        let header = ['Código', 'Nome fantasia', 'Contato', 'Responsável', 'Status', 'Orçamento', 'Total', 'Data da emissão'];
        const rows = [];

        // if(hasPermission('view_margem_order')){
        //     header.push('Dif. de margem');
        // }

        // header.push('Conferência');

        if(hasPermission('delete_order')){
            header.push('');
        }
        
        
        orders.map(order => {
            let buttons = [];
            let data = [
                order.code,
                order?.customer_company_trade_name,
                order?.customer_name,
                order?.admin_name,
                <>
                    <DotStatus color={order?.status?.color} size={15} />
                    {order?.status?.name}
                </>,
                order.budget ? <Link target={`_blank`} to={`/quote/edit/${order.budget.id}`}>{order.budget.code}</Link> : '',
                `R$ ${convertIntToMoney(order?.total)}`,
                moment(order.created_at).format('DD/MM/YYYY HH:mm'),
            ];

            // if(hasPermission('view_margem_order')){
            //     data.push(
            //         <span className={`indicator ${order?.margem_real >= order?.margem_orcamento ? 'positive' : 'negative'}`}>
            //             {convertIntToMoney(order?.margem_dif, true) }
            //         </span>
            //     );
            // }
            // data.push(countOrderPendingConferencesTag(order));
            if(hasPermission('delete_order')){
                buttons.push(<Button type={`delete`} svg={`delete`} action={() => setOrderToDelete(order.id)} />)
            }
            rows.push({
                data,
                to: `/order/edit/${order?.id}`,
                buttons,
                id: order.id,
            });
        });

        setData({header, rows});
    }

    const deleteOrder = () => {
        setLoading(true);

        api.delete(`/order/${orderToDelete}`).then(res => {
            getOrders();
            setOrderToDelete(null);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const deleteMultipleOrders = () => {
        setLoading(true);

        api.delete(`/order/multiple`, {
            data: {
                ids: selectedIds
            }
        }).then(res => {
            setSelectedIds([]);
            getOrders();
            setShowConfirmMultipleDelete(false);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const handleSetFilter = value => {
        setCurrentPage(1);
        setFilters(value);
    }

    const handleSetCustomFilters = value => {
        setCustomFilters(value);
        setCurrentPage(1);
    }

    return (
        <>
            <ModalExport
                showModal={showExport}
                setShowModal={setShowExport}
                url={`${process.env.REACT_APP_URL}/order/export`}
            />
            <ModalDelete
                show={showConfirmMultipleDelete}
                text={<p>Tem certeza que deseja apagar <strong>{selectedIds.length} {selectedIds.length > 1 ? 'pedidos' : 'pedido'}</strong>?</p>}
                action={deleteMultipleOrders}
                loading={loading}
                close={() => setShowConfirmMultipleDelete(false)}
            />
            <ModalDelete
                show={orderToDelete !== null}
                action={deleteOrder}
                loading={loading}
                close={() => setOrderToDelete(null)}
            />
            <ModalFilter
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                customFilters={customFilters}
                setCustomFilters={handleSetCustomFilters}
            />
            <PanelTemplate id="order-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Nome fantasia', key: '[customer_company_trade_name]', operation: 'contain', type: 'text' },
                                {name: 'Razão social', key: '[customer_company_company_name]', operation: 'contain', type: 'text' },
                                {name: 'E-mail do contato', key: '[customer_email]', operation: 'contain', type: 'text' },
                                {name: 'Código do pedido', key: '[code]', operation: 'equal', type: 'text' }
                            ]}
                            filter={handleSetFilter}
                        />
                    }

                    right={
                        <>
                            {hasPermission('delete_order') && selectedIds.length > 0 &&
                                <Button 
                                type={`secondary`} 
                                size={`small`} 
                                svg={`delete`}
                                text={`Excluir ${selectedIds.length} selecionados`} 
                                action={() => setShowConfirmMultipleDelete(true)}  
                                />
                            }
                            {hasPermission('export_order') &&
                                <Button 
                                type={`secondary`} 
                                size={`small`} 
                                text={`Exportar pedidos`} 
                                action={() => setShowExport(true)}  
                                />
                            }
                            <Button type={`secondary`} size={`small`} text={`Filtrar`} svg="filter" action={() => setShowFilter(true)} />
                        </>
                    }
                />
                <ul className="filter-tab">
                    <li>
                        <a href="#" className={selectedStatus === null ? 'active' : ''} onClick={e => {
                        e.preventDefault();
                        setCurrentPage(1);
                        setSelectedStatus(null);
                    }}>Todos</a></li>
                    {statuses.map((status, index) => (
                        <li key={index}>
                            <a href="#" className={selectedStatus === status.id ? 'active' : ''} onClick={e => {
                                e.preventDefault();
                                setCurrentPage(1);
                                setSelectedStatus(status.id);
                            }}>
                                {status.name}
                            </a>
                        </li>
                    ))}
                </ul>
                <Box className={`no-padding with-pagination with-filter-inline`}>
                    <Table 
                        checks={hasPermission('delete_order')}
                        selectedIds={selectedIds}
                        setSelectedIds={setSelectedIds}
                        header={data.header} 
                        rows={data.rows} 
                        loading={loading}
                    />
                </Box>
                {!loading &&
                    <Pagination
                        currentPage={currentPage}
                        lastPage={lastPage}
                        perPage={perPage}
                        totalResults={totalResults}
                        hasNextPage={hasNextPage}
                        hasPrevPage={hasPrevPage}
                        setCurrentPage={setCurrentPage}
                    />
                }
            </PanelTemplate>
        </>
    );
}

export default OrderList;