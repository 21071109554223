import React, { useEffect, useState } from "react";

import PanelTemplate from "../../templates/Panel";
import DotStatus from "../../components/DotStatus";
import BoxTabs from "../../components/BoxTabs";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import InputRadio from "../../components/InputRadio";
import InputEditor from "../../components/InputEditor";
import InputCheckboxes from "../../components/InputCheckboxes";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import InputColors from "../../components/InputColors";
import PageHeader from "../../components/PageHeader";
import InputTextarea from "../../components/InputTextarea";
import Box from "../../components/Box";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import { createOptions } from "../../helpers/selects";
import moment from "moment";
import { convertIntToMoney, convertMoenyToInt } from "../../helpers/conversions";
import ModalProduct from "./ModalProduct";
import { toast } from "react-toastify";
import { ModalDelete } from "../../components/ModalDelete";
import { getPlano, hasPermission, hasQuoteDeletePermission } from "../../services/auth";
import SelectBillingCompany from "../../components/selects/SelectBillingCompany";
import SelectStatus from "../../components/selects/SelectStatus";
import SelectCustomerCompany from "../../components/selects/SelectCustomerCompany";
import SelectCustomer from "../../components/selects/SelectCustomer";
import SelectAdmin from "../../components/selects/SelectAdmin";
import InputEditorFull from "../../components/InputEditorTiny";
import InputCheckboxUnique from "../../components/InputCheckboxUnique";

const budgetTypes = [
    {label: 'Pessoa física', value: 'PF'},
    {label: 'Pessoa jurídica', value: 'PJ'}
];

const yesOrNoOptions = [
    {label: 'Sim', value: true},
    {label: 'Não', value: false}
];

const shippingTypes = [
    {label: 'CIF - Não aparece no PDF', value: 'CIF'},
    {label: 'FOB - Aparece no PDF', value: 'FOB'}
];

const QuoteDetailsTab = ({
    quote,
    selectedBillingCompany,
    setSelectedBillingCompany,
    selectedStatus,
    setSelectedStatus,
    selectedBudgetType,
    setSelectedBudgetType,
    selectedCustomerCompany,
    setSelectedCustomerCompany,
    selectedCustomer,
    setSelectedCustomer,
    selectedAdmin,
    setSelectedAdmin,
    email,
    phone,
    total,
    comments,
    setComments,
    selectedShippingType,
    setSelectedShippingType,
    paymentTerm,
    setPaymentTerm,
    paymentForm,
    setPaymentForm,
    shippingFee,
    setShippingFee,
    shippingTime,
    setShippingTime,
    shippingConsult,
    setShippingConsult,
    adminComments,
    setAdminComments,
    setCustomerData
}) => {
    return (
        <div>
            <div className="row">
                <div className="col-3">
                    <Input
                        label={`Código do orçamento`}
                        value={quote?.code}
                        disabled={true}
                    />
                </div>
                <div className="col-3">
                    <SelectBillingCompany
                        selectedBillingCompany={selectedBillingCompany}
                        setSelectedBillingCompany={setSelectedBillingCompany}
                    />
                </div>
                <div className="col-3">
                    <SelectStatus
                        type="budget"
                        selectedStatus={selectedStatus}
                        setSelectedStatus={setSelectedStatus}
                    />
                </div>
                <div className="col-3">
                    <Input
                        label={`Data da solicitação`}
                        value={quote?.created_at ? moment(quote.created_at).format('DD/MM/YYYY HH:mm') : ''}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <InputSelect
                        label={`Tipo do cliente`}
                        options={budgetTypes}
                        value={selectedBudgetType}
                        change={setSelectedBudgetType}
                    />
                </div>
                {selectedBudgetType === "PJ" &&
                    <div className="col-3">
                        <SelectCustomerCompany
                            selectedCustomerCompany={selectedCustomerCompany}
                            setSelectedCustomerCompany={setSelectedCustomerCompany}
                        />
                    </div>
                }
                <div className="col-3">
                    <SelectCustomer
                        relatedWithCompany={selectedBudgetType === 'PJ'}
                        companyId={selectedCustomerCompany}
                        selectedCustomer={selectedCustomer}
                        setSelectedCustomer={setSelectedCustomer}
                        setCustomerData={setCustomerData}
                    />
                    {/* <InputSelect
                        label={`Pessoa`}
                        options={createOptions(customers, ['code', 'name'], 'id')}
                        value={selectedCustomer}
                        change={setSelectedCustomer}
                    /> */}
                </div>
                <div className="col-3">
                    <SelectAdmin
                        selectedAdmin={selectedAdmin}
                        setSelectedAdmin={setSelectedAdmin}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <Input
                        label={`E-mail`}
                        value={email}
                        disabled={true}
                    />
                    
                </div>
                <div className="col-3">
                    <Input
                        label={`Telefone`}
                        value={phone}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <InputEditorFull label={`Observações`} value={adminComments} change={setAdminComments} />
                </div>
                <div className="col-6">
                    <InputTextarea rows={9} label={`Observações do cliente`} disabled={true} value={comments} />
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <Input 
                        label={`Total do orçamento`}
                        value={total}
                        disabled={true}
                    />
                </div>
                <div className="col-3">
                    <Input
                        label={`Condição de pagamento`} 
                        value={paymentTerm}
                        change={setPaymentTerm}
                        placeholder={`Ex: 30 dias`}
                    />
                </div>
                <div className="col-3">
                    <Input
                        label={`Forma de pagamento`} 
                        value={paymentForm}
                        change={setPaymentForm}
                        placeholder={`Ex: Boleto`}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <Input 
                        label={`Valor do frete`}
                        value={shippingConsult ? '' : shippingFee}
                        change={setShippingFee}
                        mask={`convertToMoney`}
                        disabled={shippingConsult}
                    />
                    <div style={{ marginTop: -10 }}>
                        <InputCheckboxUnique checked={shippingConsult} change={setShippingConsult} label={`A consultar`} />
                    </div>
                </div>
                <div className="col-3">
                    <Input 
                        label={`Prazo de entrega`}
                        value={shippingTime}
                        change={setShippingTime}
                    />
                </div>
                <div className="col-3">
                    <InputSelect
                        label={`Tipo do frete`}
                        options={shippingTypes}
                        value={selectedShippingType}
                        change={setSelectedShippingType}
                    />
                </div>
            </div>
        </div>
    );
}

export const QuoteEdit = () => {
    const { id } = useParams();
    const navigete = useNavigate();

    const [loading, setLoading] = useState(true);
    const [currentTab, setCurrentTab] = useState(0);

    const [quote, setQuote] = useState(null);

    const [selectedBudgetType, setSelectedBudgetType] = useState(null);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedBillingCompany, setSelectedBillingCompany] = useState(null);
    const [selectedCustomerCompany, setSelectedCustomerCompany] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const [items, setItems] = useState([]);
    const [itemsData, setItemsData] = useState({header: [], rows: []});

    const [proposalsData, setProposalsData] = useState({header: [], rows: []});

    const [attachmentData, setAttachmentData] = useState({header: [], rows: []});
    
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comments, setComments] = useState('');
    const [adminComments, setAdminComments] = useState('');
    const [total, setTotal] = useState('R$ 0,00');
    
    const [selectedShippingType, setSelectedShippingType] = useState(null);
    const [shippingConsult, setShippingConsult] = useState(true);
    const [shippingFee, setShippingFee] = useState('R$ 0,00');
    const [shippingTime, setShippingTime] = useState(0);

    const [paymentTerm, setPaymentTerm] = useState('');
    const [paymentForm, setPaymentForm] = useState('');

    const [itemToEdit, setItemToEdit] = useState(null);
    const [showAddProduct, setShowAddProduct] = useState(false);

    const [productToDelete, setProductToDelete] = useState(null);

    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        getQuote();
    }, []);

    useEffect(() => {
        if(quote !== null) getItems();
    }, [quote]);

    useEffect(() => {
        generateItemsData();
        getTotal();
    }, [items]);

    useEffect(() => {
        if(itemToEdit !== null || productToDelete !== null){
            updateQuote(false, false, false, true);
        }
    }, [itemToEdit, productToDelete]);

    useEffect(() => {
        setSelectedCustomer(null);
        setSelectedCustomerCompany(null);
    }, [selectedBudgetType]);

    useEffect(() => {
        if(quote) generateProposalsData();
    }, [quote]);

    const getQuote = () => {
        setLoading(true);

        api.get(`/budget/${id}`).then(res => {
            let resource = res.data.resource;
            setQuote(resource);
            setSelectedBudgetType(resource.type);
            setSelectedStatus(resource.status_id);
            setSelectedAdmin(resource.admin_id);
            setSelectedBillingCompany(resource.billing_company_id);
            setSelectedCustomerCompany(resource.company_id);
            setSelectedCustomer(resource.customer_id);
            setSelectedShippingType(resource.shipping_type);
            setShippingFee('R$ ' + convertIntToMoney(resource.shipping_fee));
            setShippingTime(resource.shipping_time);
            setShippingConsult(resource.shipping_consult);
            setPaymentForm(resource.payment_form);
            setPaymentTerm(resource.payment_term);
            setComments(resource.comments ?? '');
            setAdminComments(resource.admin_comments ?? '');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const updateQuote = (createProposal = false, send = false, createOrder = false, forcedUpdate = false) => {
        if(!forcedUpdate){
            setLoading(true);
        }

        api.put(`/budget/${quote.id}`, {
            send,
            create_proposal: createProposal,
            type: selectedBudgetType,
            billing_company_id: selectedBillingCompany,
            company_id: selectedCustomerCompany,
            customer_id: selectedCustomer,
            status_id: selectedStatus,
            admin_id: selectedAdmin,
            products: items,
            shipping_fee: convertMoenyToInt(shippingFee),
            shipping_type: selectedShippingType,
            shipping_time: shippingTime,
            shipping_consult: shippingConsult,
            payment_form: paymentForm,
            payment_term: paymentTerm,
            comments,
            admin_comments: adminComments
        }).then(res => {
            if(createOrder){
                navigete(`/order/create/${quote?.id}`);
            } else {
                if(!forcedUpdate){
                    getQuote();
                    if(createProposal){
                        setCurrentTab(1);
                        let wrapper = document.querySelector('.wrapper');
                        wrapper.scrollTop = 0;
                    }
                    toast.success('Orçamento atualizado com sucesso');
                }
            }
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const getTotal = () => {
        let toTotal = 0;
        items.map(item => {
            toTotal += item.price * item.quantity;
        });
        setTotal(`R$ ${convertIntToMoney(toTotal)}`);
    }

    const getItems = () => {
        setItems(quote.items);
    }

    const deleteItem = (id) => {
        setItems(prev => {
            let toItems = [];
            prev.map(item => {
                if(item.id !== id){
                    toItems.push(item);
                }
            });
            return toItems;
        });
        setItemToEdit(null);
    }

    const generateItemsData = () => {
        const header = ['Imagem', 'SKU', 'Nome', 'Quant.', 'Preço unit.', 'Total', ''];
        const rows = [];
        
        items.map(item => {
            // const image = <img src={logo.image_url.small} />;
            let image =  null;
            if(item.subproduct.image !== null){
                image = <img src={item.subproduct.image.image_url.small} />;
            }
            if(item.subproduct.product.images.length > 0 && image === null){
                image = <img src={item.subproduct.product.images[0].image_url.small} />;
            }
            if(image === null){
                image = <img src={ require(`../../assets/images/svgs/default.svg`).default } />;
            }

            rows.push({
                data: [
                    image, 
                    item.subproduct.sku,
                    item.subproduct.product.name,
                    item.quantity, 
                    `R$${convertIntToMoney(item.price)}`, 
                    `R$${convertIntToMoney(item.price * item.quantity)}`
                    // <Button
                    //     type={`transparent`}
                    //     size={`samll`}
                    //     svg={`delete`}
                    //     action={() => deleteItem(item.id)}
                    // />
                ],
                buttons: [
                    <Button type={`delete`} svg={`delete`} action={() => setProductToDelete(item.id)} />
                ],
                action: () => setItemToEdit(item)
            });
        });

        setItemsData({header, rows});
    }

    // const editItem = item => {
    //     if(!selectedBillingCompany){
    //         toast.error('Escolha a empresa de faturamento');
    //         return;
    //     }

    //     setItemToEdit(item);
    // }

    const generateProposalsData = () => {
        const header = ['Data do envio',  'Enviada', 'Responsável', 'Nome do contato', 'E-mail do contato', 'Total da proposta'];
        const rows = [];
        
        quote.proposals.map(proposal => {
            rows.push({
                data: [
                    moment(proposal.created_at).format('DD/MM/YYYY HH:mm'),
                    proposal.sent ? 'Sim' : 'Não',
                    proposal.admin_name, 
                    proposal.customer_name,
                    proposal.customer_email,
                    `R$${convertIntToMoney(proposal.total)}`,
                ],
                action: () => navigete(`/proposal/${proposal.id}`)
            });
        });

        setProposalsData({header, rows});
    }

    const setCustomerData = customer => {
        setEmail(customer?.email);
        setPhone(customer?.phone);
    }

    const ProposalsTab = () => {
        return (
            <Table header={proposalsData.header} rows={proposalsData.rows}/>
        );
    }

    const AttachmentTab = () => {
        return (
            <Table header={attachmentData.header} rows={attachmentData.rows}/>
        );
    }

    const deleteQuote = () => {
        setLoading(true);

        api.delete(`/budget/${id}`).then(res => {
            navigete(`/quote/list`);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const deleteProduct = () => {
        setLoading(true);

        api.delete(`/budget/item/${productToDelete}`).then(res => {
            setProductToDelete(null);
            getQuote();
        }).catch(error => {
            setLoading(false);
            renderError(error);
        });
    }

    return (
        <>
            {showAddProduct &&
                <ModalProduct
                    quote={quote}
                    getQuote={getQuote}
                    updateQuote={updateQuote}
                    selectedBillingCompany={selectedBillingCompany}
                    setSelectedBillingCompany={setSelectedBillingCompany}
                    close={() => setShowAddProduct(false)}
                />
            }
            {itemToEdit !== null &&
                <ModalProduct
                    quote={quote}
                    getQuote={getQuote}
                    updateQuote={updateQuote}
                    itemToEdit={itemToEdit}
                    setItemToEdit={setItemToEdit}
                    selectedBillingCompany={selectedBillingCompany}
                    setSelectedBillingCompany={setSelectedBillingCompany}
                    close={() => setItemToEdit(null)}
                />
            }
            <ModalDelete
                show={productToDelete !== null}
                action={deleteProduct}
                loading={loading}
                close={() => setProductToDelete(null)}
            />
            <ModalDelete
                show={confirmDelete}
                action={deleteQuote}
                loading={loading}
                close={() => setConfirmDelete(false)}
            />
            <PanelTemplate id="quote-edit">
                <BoxTabs
                    setTab={setCurrentTab}
                    tab={currentTab}
                    titles={[
                        'Detalhes do orçamento',
                        'Propostas',
                        // 'Anexos',
                    ]}
                    contents={[
                        <QuoteDetailsTab
                            quote={quote}
                            selectedBillingCompany={selectedBillingCompany}
                            setSelectedBillingCompany={setSelectedBillingCompany}
                            selectedStatus={selectedStatus}
                            setSelectedStatus={setSelectedStatus}
                            selectedBudgetType={selectedBudgetType}
                            setSelectedBudgetType={setSelectedBudgetType}
                            selectedCustomerCompany={selectedCustomerCompany}
                            setSelectedCustomerCompany={setSelectedCustomerCompany}
                            selectedCustomer={selectedCustomer}
                            setSelectedCustomer={setSelectedCustomer}
                            selectedAdmin={selectedAdmin}
                            setSelectedAdmin={setSelectedAdmin}
                            email={email}
                            phone={phone}
                            total={total}
                            comments={comments}
                            setComments={setComments}
                            adminComments={adminComments}
                            setAdminComments={setAdminComments}
                            selectedShippingType={selectedShippingType}
                            setSelectedShippingType={setSelectedShippingType}
                            shippingFee={shippingFee}
                            setShippingFee={setShippingFee}
                            shippingTime={shippingTime}
                            setShippingTime={setShippingTime}
                            shippingConsult={shippingConsult}
                            setShippingConsult={setShippingConsult}
                            paymentTerm={paymentTerm}
                            setPaymentTerm={setPaymentTerm}
                            paymentForm={paymentForm}
                            setPaymentForm={setPaymentForm}
                            setCustomerData={setCustomerData}
                        />,
                        <ProposalsTab />,
                        // <AttachmentTab />,
                    ]}
                />
                
                <Box
                    className={`no-padding`}
                    title={`Produtos do orçamento`}
                    tools={
                        <Button 
                            type={`secondary`}
                            size={`small`}
                            text={`Adicionar produto`}
                            loading={loading}
                            svg={`plus-blue`}
                            action={() => setShowAddProduct(true)}
                        />
                    }
                >
                    <Table header={itemsData.header} rows={itemsData.rows}/>
                </Box>
                
                <PageFooter spaceBetween={true}>
                    <div>
                        {hasPermission('edit_budget') &&
                            <Button type={`primary`} loading={loading} size={`small`} svg={`save-white`} text={`Salvar alterações`} action={updateQuote} />
                        }
                        {hasPermission('create_proposal') &&
                            <>
                                <Button type={`secondary`} loading={loading} size={`small`} text={`Atualizar e criar proposta`} action={() => updateQuote(true, false)} />
                                {getPlano() !== 'basic' &&
                                    <Button type={`secondary`} loading={loading} size={`small`} svg={`send`} text={`Atualizar e enviar`} action={() => updateQuote(true, true)} />
                                }
                            </>
                        }
                    </div>
                    <div>
                        {hasPermission('create_order') && getPlano() !== 'basic' && getPlano() !== 'basic-plus' &&
                            <Button type={`secondary`} size={`small`} loading={loading} text={`Salvar e emitir pedido`} action={() => updateQuote(false, false, true)} />
                        }
                        {hasQuoteDeletePermission(quote) &&
                            <Button type={`secondary`} size={`small`} loading={loading} text={`Excluir`} svg={`delete-small`} action={() => setConfirmDelete(true)} />
                        }
                    </div>
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default QuoteEdit;