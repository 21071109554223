import React, { useEffect, useState } from "react";

import InputSelect from "../../../../components/InputSelect";
import Input from "../../../../components/Input";
import InputRadio from "../../../../components/InputRadio";
import { createOptions } from '../../../../helpers/selects';
import { convertIntToMoney, convertMoenyToInt } from "../../../../helpers/conversions";
import Button from "../../../../components/Button";
import { calculateCustomizationTotal } from "../customizationCalculate";

const calculationTypeOptions = [
    { label: 'Pré-cadastrado', value: 'automatic' },
    { label: 'Manual', value: 'manual' }
];

const CustomizationSection = ({
    index,
    product,
    customizations,
    productCustomization,
    productCustomizations,
    setProductCustomizations,
    productQuantity
}) => {
    const [suppliers, setSuppliers] = useState([]);
    const [calculationPer, setCalculationPer] = useState('unit');
    const [customizationUnitCostError, setCustomizationUnitCostError] = useState('');
    const [customizationTotalCostError, setCustomizationTotalCostError] = useState('');

    const [tableOptions, setTableOptions] = useState([{ label: 'Tabela cadastrada', value: 'registered' }]);

    useEffect(() => {
        if(product){
            getTableOptions();
            checkSpot();
        }
    }, [product]);

    const checkSpot = () => {
        let customizations = getCustomizations();
        if(productCustomization.spot_customization && customizations.filter(customization => customization.CustomizationTypeName === productCustomization.spot_customization ).length === 0) {
            deleteCustomization();
        }
    }

    const getTableOptions = () => {
        if(product.origin === 'integracao_spot' && product.spot_components.length > 0){
            setTableOptions(prev => [prev[0],  { label: 'Tabela SPOT', value: 'spot' }]);
        }
    }

    useEffect(() => {
        getSuppliers();
        getCalculationPer();
    }, [,productCustomization.selectedCustomization]);

    useEffect(() => {
        handleQuantityChange(productQuantity);
    }, [productQuantity]);

    useEffect(() => {
        getCustomizationUnitCost();
    }, 
    [
        productCustomization.calculationType,
        productCustomization.selectedSupplierCustomization,
        productCustomization.selectedCustomization,
        productCustomization.quantity,
        productCustomization.quantityColors,
        suppliers
    ]);

    useEffect(() => {
        calculateCustomizationTotalCost();
    }, [
        product,
        productCustomization.table,
        productCustomization.spot_component,
        productCustomization.spot_location,
        productCustomization.spot_area,
        productCustomization.spot_customization,
        productCustomization.quantity,
        productCustomization.quantityColors,
        productCustomization.customizationUnitCost
    ]);

    const zeroUnitCost = () => {
        handleCustomizationUnitCostChange( `R$ 0,00` );
    }

    const getSuppliers = () => {
        let toSuppliers = [];
        let customization = customizations.filter(c => c.id === productCustomization.selectedCustomization);
        if(customization.length > 0){
            customization = customization[0];
            customization.companies.map(company => {
                toSuppliers.push({
                    id: company.id,
                    name: company.trade_name,
                    type: "PJ"
                });
            });
            customization.customers.map(customer => {
                toSuppliers.push({
                    id: customer.id,
                    name: customer.name,
                    type: "PF"
                });
            });
        }
        setSuppliers(toSuppliers);
    }

    const calculateSpotCustomizationTotalCost = () => {
        let error = 'Preço não encontrado.';

        let scalePrices = getScalePrices();
        console.log(scalePrices)
        let total = 0;
        if(scalePrices.length > 0){
            scalePrices.map(scalePrice => {
                if(scalePrice.MinQt <= productQuantity){
                    total = scalePrice.Price * productQuantity * 100
                    error = '';
                }
            });
        }
        setCustomizationTotalCostError(error);
        handleCustomizationTotalCostChange( `R$ ${convertIntToMoney(total)}`);
    }

    const calculateCustomizationTotalCost = () => {
        if(productCustomization.table === 'spot'){
            calculateSpotCustomizationTotalCost();
            return;
        }
        let total = calculateCustomizationTotal(
            productCustomization.quantity,
            convertMoenyToInt( productCustomization.customizationUnitCost),
            getCalculationPer(),
            productCustomization.customizationUnitCostType
        );
        let customization = customizations.filter(c => c.id === productCustomization.selectedCustomization);
        if(customization.length > 0){
            customization = customization[0];
            if(customization.multiply_colors){
                total = total * productCustomization.quantityColors;
            }
        }

        handleCustomizationTotalCostChange( `R$ ${convertIntToMoney(total)}`);
    }

    const handleTableChange = value => {
        handleParamChange('table', value);
    }

    const handleSpotComponentChange = value => {
        handleParamChange('spot_component', value);
    }

    const handleSpotLocationChange = value => {
        handleParamChange('spot_location', value);
    }

    const handleSpotAreaChange = value => {
        handleParamChange('spot_area', value);
    }

    const handleSpotCustomizationChange = value => {
        handleParamChange('spot_customization', value);
    }

    const handleCalculationTypeChange = value => {
        handleParamChange('calculationType', value);
    }

    const handleSelectedCustomizationChange = value => {
        if(value !== productCustomization.selectedCustomization){
            handleSelectedSupplierCustomizationChange(null);
            zeroUnitCost();
        }
        handleParamChange('selectedCustomization', value);
    }

    const handleSelectedSupplierCustomizationChange = value => {
        let supplier = getSupplier(value);
        handleParamChange('selectedSupplierTypeCustomization', supplier ? supplier.type : null);
        zeroUnitCost();
        handleParamChange('selectedSupplierCustomization', value);
    }

    const handleQuantityColorsChange = value => {
        handleParamChange('quantityColors', value);
    }

    const handleQuantityChange = value => {
        handleParamChange('quantity', value);
    }

    const handleCustomizationUnitCostChange = value => {
        handleParamChange('customizationUnitCost', value);
    }

    const handleCustomizationUnitCostTypeChange = value => {
        handleParamChange('customizationUnitCostType', value);
    }

    const handleCustomizationTotalCostChange = value => {
        handleParamChange('customizationTotalCost', value);
    }

    const getCalculationPer = () => {
        let toCalculationPer = 'unit';
        let customization = customizations.filter(c => c.id === productCustomization.selectedCustomization);
        
        if(customization.length > 0){
            customization = customization[0];
            toCalculationPer = customization.calculation_per;
        }

        setCalculationPer(toCalculationPer);
        return toCalculationPer;
    }

    const handleParamChange = (param, value) => {
        console.log(param, value)
        setProductCustomizations(prev => {
            let toProductCustomizations = [];

            prev.map((c, i) => {
                if(i === index){
                    let updated = {...c};
                    updated[param] = value;
                    toProductCustomizations.push(updated);
                } else {
                    toProductCustomizations.push(c);
                }
            });

            return toProductCustomizations;
        });
    }

    const getSupplier = id => {
        let supplier = suppliers.filter(s => s.id === id);
        return supplier.length > 0 ? supplier[0] : null;
    }

    const getCustomizationUnitCost = () => {
        setCustomizationUnitCostError('');

        if(productCustomization.calculationType === 'manual') return;

        let cost = 0;
        let type = null;
        let error = 'Preço do fornecedor não encontrado.';
        let foundPrice = false;
        let prices = [];
        const quantity = productCustomization.quantity;
        let thousands = quantity / 1000;
        thousands = Math.ceil(thousands);

        let customization = customizations.filter(c => c.id === productCustomization.selectedCustomization);
        if(customization.length > 0){
            let supplier = getSupplier(productCustomization.selectedSupplierCustomization);

            customization = customization[0];
            console.log('calc', productCustomization, suppliers)

            if(supplier?.type === "PJ"){
                customization.companies.map(company => {
                    if(company.id === productCustomization.selectedSupplierCustomization){
                        prices.push(company.pivot);
                    }
                });
            }
            if(supplier?.type === "PF"){
                customization.customers.map(customer => {
                    if(customer.id === productCustomization.selectedSupplierCustomization){
                        prices.push(customer.pivot);
                    }
                });
            }
        }

        prices = sortPrices(prices);

        prices.map(price => {
            if(customization.calculation_per === 'unit'){
                if(price.init_qtd <= quantity && price.final_qtd >= quantity){
                    cost = price.cost;
                    type = price.cost_type;
                    error = '';
                    foundPrice = true;
                }
            } else {
                if(price.thousand === thousands){
                    cost = price.cost;
                    type = price.cost_type;
                    error = '';
                    foundPrice = true;
                }
            }
        });

        if(!foundPrice && prices.length > 0){
            let price = prices[prices.length -1];
            cost = price.cost;
            type = price.cost_type;
            error = '';
        }

        handleCustomizationUnitCostChange( `R$ ${convertIntToMoney(cost)}` );
        handleCustomizationUnitCostTypeChange(type);
        setCustomizationUnitCostError(error);
    }
    
    const sortPrices = prices => {
        if(calculationPer === 'unit'){
            return prices.sort((a, b) => a.final_qtd - b.final_qtd);
        } else {
            return prices.sort((a, b) => a.thousand - b.thousand);
        }
    }

    const deleteCustomization = () => {
        let toProductCustomizations = [];
        productCustomizations.map((productCustomization, i) => {
            if(i !== index){
                toProductCustomizations.push(productCustomization);
            }
        });

        setProductCustomizations([...toProductCustomizations]);
    }

    const getLocations = () => {
        if(!product) return [];
        let component = product.spot_components.filter(component => component.Name === productCustomization.spot_component);
        if(component.length === 0) return [];
        return component[0].Locations;
    }

    const getAreas = () => {
        if(!product) return [];
        let locations = getLocations();
        let location = locations.filter(location => location.Name === productCustomization.spot_location);
        if(location.length === 0) return [];
        return location[0].CustomizationAreas;
    }

    const getCustomizations = () => {
        if(!product) return [];
        let areas = getAreas();
        let area = areas.filter(area => area.AreaName === productCustomization.spot_area);
        if(area.length === 0) return [];
        return area[0].ProductCustomizationTables;
    }

    const getScalePrices = () => {
        if(!product) return [];
        let customizations = getCustomizations();
        let customization = customizations.filter(customization => customization.CustomizationTypeName === productCustomization.spot_customization);
        if(customization.length === 0) return [];
        let tablePrice = customization[0].ProductCustomizationTableOptions.filter(table => table.Colors === parseInt(productCustomization.quantityColors));
        if(tablePrice.length === 0) return [];
        return tablePrice[0].ScalePrices;
    }    

    return (
        <>
            <div className="modal-section-title">
                <div className="left">
                    <h2>Gravação</h2>
                    <Button type={`transparent`} size={`small`} svg={`delete-small`} action={deleteCustomization} />
                </div>
                <div className="right">
                    {tableOptions.length > 1 &&
                        <InputRadio
                            className={`no-margin`}
                            options={tableOptions}
                            value={productCustomization.table}
                            change={handleTableChange}
                        />
                    }
                </div>
            </div>

            {product && productCustomization.table === 'spot' &&
                <div className="section">
                    <div className="row">
                        <div className="col-4">
                            <InputSelect
                                label={`Componente`}
                                options={createOptions(product.spot_components, 'Name', 'Name')}
                                value={productCustomization.spot_component}
                                change={handleSpotComponentChange}
                            />
                        </div>
                        <div className="col-4">
                            {productCustomization.spot_component &&
                                <InputSelect
                                    label={`Localização`}
                                    options={createOptions(getLocations(), 'Name', 'Name')}
                                    value={productCustomization.spot_location}
                                    change={handleSpotLocationChange}
                                />
                            }
                        </div>
                        <div className="col-4">
                            {productCustomization.spot_location &&
                                <InputSelect
                                    label={`Área da gravação`}
                                    options={createOptions(getAreas(), 'AreaName', 'AreaName')}
                                    value={productCustomization.spot_area}
                                    change={handleSpotAreaChange}
                                />
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            {productCustomization.spot_area &&
                                <InputSelect
                                    label={`Gravação`}
                                    options={createOptions(getCustomizations(), 'CustomizationTypeName', 'CustomizationTypeName')}
                                    value={productCustomization.spot_customization}
                                    change={handleSpotCustomizationChange}
                                />
                            }
                        </div>
                        <div className="col-4">
                            {productCustomization.spot_customization &&
                                <Input
                                    label={`Num. de cores`}
                                    value={productCustomization.quantityColors}
                                    change={handleQuantityColorsChange}
                                    mask={`convertToInt`}
                                />
                            }
                        </div>
                        <div className="col-4">
                            {productCustomization.spot_customization &&
                                <Input
                                    label={`Total`}
                                    value={productCustomization.customizationTotalCost}
                                    disabled={true}
                                    error={customizationTotalCostError}
                                />
                            }
                        </div>
                    </div>
                    
                </div>
            }
        
            {productCustomization.table === 'registered' &&
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <InputSelect 
                                options={createOptions(customizations, 'name', 'id', true)}
                                value={productCustomization.selectedCustomization}
                                change={handleSelectedCustomizationChange}
                                label={`Gravação`}
                            />
                        </div>
                        <div className="col-6">
                            <InputSelect 
                                label={`Fornecedor`}
                                options={createOptions(suppliers, 'name', 'id', true)}
                                value={productCustomization.selectedSupplierCustomization}
                                change={handleSelectedSupplierCustomizationChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <Input
                                label={`Num. de cores`}
                                value={productCustomization.quantityColors}
                                change={handleQuantityColorsChange}
                                mask={`convertToInt`}
                            />
                        </div>
                        {/* <div className="col-3">
                            <Input
                                label={`Quantidade`}
                                value={productCustomization.quantity}
                                change={handleQuantityChange}
                                mask={`convertToInt`}
                            />
                        </div> */}
                        <div className="col-3">
                            <InputSelect
                                label={`Origem do custo`}
                                options={calculationTypeOptions}
                                value={productCustomization.calculationType}
                                change={handleCalculationTypeChange}
                            />
                        </div>
                        <div className="col-3">
                            <Input
                                label={calculationPer === 'unit' ? 'Custo unit.' : 'Custo milheiro'} 
                                value={productCustomization.customizationUnitCost}
                                change={handleCustomizationUnitCostChange}
                                mask={`convertToMoney`}
                                disabled={productCustomization.calculationType === 'automatic'}
                                error={customizationUnitCostError}
                            />
                        </div>
                        <div className="col-3">
                            <Input
                                label={`Total`}
                                value={productCustomization.customizationTotalCost}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default CustomizationSection;